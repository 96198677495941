<footer class="main-footer">
  <div class="float-right d-none d-sm-block" style="margin-right:10px;">
    <b>Version</b> {{ appVersion }}
  </div>
  <strong
    >Copyright &copy; 2021 </strong
    >
    <a href="https://swapinfotech.com" class="branding">SwapInfotech.com</a>
  All rights reserved.
</footer>
