/*import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-about-us-list',
  templateUrl: './about-us-list.component.html',
  styleUrls: ['./about-us-list.component.scss']
})
export class AboutUsListComponent implements OnInit {
  private category: string;
  public href: string = "";
  dtOptions: DataTables.Settings = {};
  public allData: any;
  private isDtInitialized: boolean = false;
  public addCatName: string;
  public addCatRoute: string;
  public editCatRoute: string;
  dtTrigger: Subject<any> = new Subject<any>();
  @ViewChild(DataTableDirective) dtElement: DataTableDirective;

  constructor(private router: Router, private apiService: ApiService, private toastr: ToastrService) {}

  

  ngOnInit(): void {
    this.href = this.router.url;
    console.log(this.router.url,'llllllllll');
    this.category = this.router.url.split('/')[1];
    // console.log(this.category);
    if(this.category === "about-us-list") {
      this.addCatName = 'Add About Us';
      this.addCatRoute = '/about-us';
      this.editCatRoute = '/edit-about-us';
    } 

    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };
    this.showItem();
  }

  async showItem() {
    const that = this;
    let catTypeListApi: any;
    if(this.category === "about-us-list") {
      catTypeListApi = this.apiService.aboutUsList();
    } 
    await catTypeListApi.subscribe((resp: any) => {
      console.log(resp.data);
      that.allData = resp.data;
      if (this.isDtInitialized) {
        this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
          dtInstance.destroy();
          this.dtTrigger.next();
        });
      } else {
        this.isDtInitialized = true;
        this.dtTrigger.next();
      }
    });
  }
 

  /*async deleteItem(id, type) {
    if (id == undefined || id == '') {
      this.toastr.error('User Id is Invalid');
      return false;
    }
    if (
      id != undefined &&
      id != '' &&
      confirm('Are You Sure, You Want To Delete?')
    ) {
      // console.log(id, type);
      if (type.split(' ')[0] === 'Internal') {
        let catTypeInDltApi: any;
        if(this.category === "news-list") {
          catTypeInDltApi = this.apiService.deleteInternalNewsById(id);
        } else if(this.category === "gmp-list") {
          catTypeInDltApi = this.apiService.deleteInternalGmpById(id);
        } else if(this.category === "regulatory-guidelines-list") {
          catTypeInDltApi = this.apiService.deleteInternalGuidelinesById(id);
        } else if(this.category === "business-list") {
          catTypeInDltApi = this.apiService.deleteInternalBusinessById(id);
        } else if(this.category === "products-services-list") {
          catTypeInDltApi = this.apiService.deleteInternalProductById(id);
        }
        await catTypeInDltApi.subscribe((result: any) => {
          if (result.success) {
            this.toastr.success(result.message);
            this.showItem();
          } else {
            this.toastr.error(result.message);
          }
        });
      } else if (type.split(' ')[0] === 'External') {
        let catTypeExDltApi: any;
        if(this.category === "news-list") {
          catTypeExDltApi = this.apiService.deleteExternalNewsById(id);
        } else if(this.category === "gmp-list") {
          catTypeExDltApi = this.apiService.deleteExternalGmpById(id);
        } else if(this.category === "regulatory-guidelines-list") {
          catTypeExDltApi = this.apiService.deleteExternalGuidelinesById(id);
        } else if(this.category === "business-list") {
          catTypeExDltApi = this.apiService.deleteExternalBusinessById(id);
        } else if(this.category === "products-services-list") {
          catTypeExDltApi = this.apiService.deleteExternalProductById(id);
        }
        await catTypeExDltApi.subscribe((result: any) => {
          if (result.success) {
            this.toastr.success(result.message);
            this.showItem();
          } else {
            this.toastr.error(result.message);
          }
        });
      }
    }
  }*/
    /*aboutUsEdit(id: string, data: any): void {
      // Navigate to the edit page with the item details
      this.router.navigate(['/about-us-list', id], {
        state: { aboutUsData: data },
      });
    }

    deleteAbout(id: string, name: string): void {
      Swal.fire({
        title: `Are you sure?`,
        text: `You are about to delete "${name}". This action cannot be undone!`,
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'Cancel',
      }).then((result) => {
        if (result.isConfirmed) {
          this.apiService.deleteAbout(id).subscribe({
            next: () => {
              Swal.fire('Deleted!', `${name} has been deleted.`, 'success');
              // Remove the item from the local array
              this.allData = this.allData.filter((item) => item._id !== id);
            },
            error: (err) => {
              Swal.fire('Error!', `Failed to delete ${name}.`, 'error');
              console.error('Delete API Error:', err);
            },
          });
        }
      });
    }
    
    
  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
*/
import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from 'src/app/utils/services/api.service';
import { ToastrService } from 'ngx-toastr';
import { DataTableDirective } from 'angular-datatables';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-about-us-list',
  templateUrl: './about-us-list.component.html',
  styleUrls: ['./about-us-list.component.scss']
})
export class AboutUsListComponent implements OnInit, OnDestroy {
  aboutUsData: any = {
    name: '',
    about_us_image: [],
    about_us: '',
    created_at: '',
  };

  category: string;
  href: string = '';
  dtOptions: DataTables.Settings = {};
  allData: any[] = [];
  isDtInitialized: boolean = false;
  addCatName: string;
  addCatRoute: string;
  editCatRoute: string;
  dtTrigger: Subject<any> = new Subject<any>();
  item_id: string;

  @ViewChild(DataTableDirective, { static: false }) dtElement: DataTableDirective;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    this.initializeRoutes();
    this.initializeDataTable();
    this.fetchAboutUsList();
  }

  initializeRoutes(): void {
    this.href = this.router.url;
    this.category = this.href.split('/')[1];

    if (this.category === 'about-us-list') {
      this.addCatName = 'Add About Us';
      this.addCatRoute = '/add-about-us';
      this.editCatRoute = '/edit-about-us';
    }
  }

  initializeDataTable(): void {
    this.dtOptions = {
      pagingType: 'full_numbers',
      pageLength: 10,
    };
  }

  fetchAboutUsList(): void {
    if (this.category === 'about-us-list') {
      this.apiService.aboutUsList().subscribe({
        next: (response: any) => {
          if (response.success) {
            this.allData = response.data;
            this.item_id = response.data[0]._id;

            if (this.isDtInitialized) {
              this.dtElement.dtInstance.then((dtInstance: DataTables.Api) => {
                dtInstance.destroy();
                this.dtTrigger.next();
              });
            } else {
              this.isDtInitialized = true;
              this.dtTrigger.next();
            }
          } else {
            this.toastr.error('Failed to load About Us list.');
          }
        },
        error: (err) => {
          console.error('Error fetching About Us list:', err);
          this.toastr.error('An error occurred while fetching the About Us list.');
        },
      });
    }
  }

  editAboutUs(item): void {
    const state = {
      data : item,
      from: 'edit'
    }
    this.router.navigateByUrl('/about-us-add/about-us-edit',{state : state });
  }

  deleteAboutUs(id: string): void {
    const confirmation = window.confirm('Are you sure you want to delete this data?');
  
    if (confirmation) {
      this.apiService.deleteAboutUsContent(id).subscribe(
        (response) => {
          if (response.success) {
            this.toastr.success(response.message);
            this.allData = this.allData.filter(item => item._id !== id);
          } else {
            this.toastr.error('Failed to delete content');
          }
        },
        (error) => {
          console.error('Error details:', error);  // Log the full error
          this.toastr.error('Error deleting content: ' + (error?.message || error));
        }
      );
    }
  }
  

  ngOnDestroy(): void {
    this.dtTrigger.unsubscribe();
  }
}
