<section class="content">
    <div class="row">
      <div class="col-12">
        <div class="card">
          <div class="card-body">
            <table 
              datatable 
              [dtOptions]="dtOptions"
              [dtTrigger]="dtTrigger" 
              class="row-border hover"
            >
              <thead>
                <tr>
                  <th>S. No.</th>
                  <th>User Name</th>
                  <!-- <th>Designation</th>
                  <th>Company</th> -->
                  <th>Email</th>
                  <th>Mobile No.</th>
                  <th>Status</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody *ngIf="allUser?.length != 0">
                <tr *ngFor="let user of allUser; let i = index">
                  <td>{{ i + 1 }}</td>
                  <td>{{ user.firstname | titlecase }} {{ user.lastname | titlecase }}</td>
                  <!-- <td>{{ user.desigination }}</td>
                  <td>{{ user.company }}</td> -->
                  <td>{{ user.email }}</td>
                  <td>{{ user.mobile }}</td>
                  <td>{{ user.status | titlecase }}</td>
                  <td>
                    <div class="btn-group">
                      <button 
                        *ngIf="user.status === 'active'"
                        type="button" 
                        class="btn btn-success"
                        data-bs-toggle="tooltip" title="Inactivate"
                        (click)="changeStaus(user._id, user.status)"
                      ><i class="far fa-check-circle"></i></button>
                      <button 
                        *ngIf="user.status !== 'active'"
                        type="button" 
                        class="btn btn-danger"
                        (click)="changeStaus(user._id, user.status)"
                        data-bs-toggle="tooltip" title="Activate"
                      ><i class="fas fa-ban"></i></button>
                      <button 
                        
                        type="button" 
                        class="btn btn-danger"
                        data-bs-toggle="tooltip" title="Delete"
                        (click)="deleteUser(user._id)"
                      ><i class="fa fa-trash" aria-hidden="true"></i></button>
                    </div>
                  </td>
                </tr>
              </tbody>
              <tbody *ngIf="allUser?.length == 0">
                <tr>
                  <td colspan="3" class="no-data-available">No data!</td>
                </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
</section>
  