import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-privacy-policy',
  templateUrl: './privacy-policy.component.html',
  styleUrls: ['./privacy-policy.component.scss']
})
export class PrivacyPolicyComponent implements OnInit {
  getData : any 
  addPrivacyPolicyForm: FormGroup
  public isAuthLoading = false;
  public isAuthDisabled = false;
  public cancelCatRoute: string;
  private _userId: string = localStorage.getItem("id");
  config_ck = {
    uiColor: '#ffffff',
    toolbarGroups: [
    // { name: 'clipboard', groups: ['clipboard', 'undo'] },
    // { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
    // { name: 'links' }, { name: 'insert' },
    // { name: 'document', groups: ['mode', 'document', 'doctools'] },
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
    // { name: 'styles' },
    { name: 'colors' }],
    resize_enabled: false,
    removePlugins: 'elementspath,save,magicline',
    extraPlugins: 'justify',
    colorButton_foreStyle: {
       element: 'font',
       attributes: { 'color': '#(color)' }
    },
    height: 188,
    removeDialogTabs: 'image:advanced;link:advanced',
    removeButtons: 'Subscript,Superscript,Anchor,Source,Table',
    format_tags: 'p;h1;h2;h3;pre;div'
 }
 @ViewChild(DataTableDirective) datatableElement: DataTableDirective
 category: string;
  footer_content: any;
  content_id: string;

  constructor(
    private formBuilder: FormBuilder,
    private config: NgbDatepickerConfig,
    private toastr: ToastrService,
    private route: ActivatedRoute,
    private router: Router,
    private apiService: ApiService
  ) { }

  ngOnInit(): void {
    this.category = this.router.url.split('/')[1];
    this.content_id = this.router.url.split('/')[2]
  

  this.addPrivacyPolicyForm = new FormGroup({
    category_name : new FormControl(),
    privacy: new FormControl(),   
    status: new FormControl(),
  });
  this.addPrivacyPolicyForm = this.formBuilder.group({
   
    // category_name: ['', [Validators.required]],    
    privacy: [[], [Validators.required]],   
    status: ['', []],
  });
  this.getContent()
}

AddPrivacyContent(){
  let formData = new FormData
  if(this.content_id == undefined){
        if (
      this.addPrivacyPolicyForm.value.privacy.trim() == undefined ||
      this.addPrivacyPolicyForm.value.privacy.trim() == ''
  ) {
    this.toastr.error('Please Enter Content');
    return false;
  }
  
  
  let reqparams = {
    "user_id" : this._userId,
    "status" : this.addPrivacyPolicyForm.get('status').value,
    "privacy" : this.addPrivacyPolicyForm.get('privacy').value,
    "category_name" : "Privacy Policy"
  };


  // formData.append('status', this.addAboutUsForm.get('status').value);
  // formData.append('category_name', this.addAboutUsForm.get('category_name').setValue('About Us'));
  // formData.append('footer_content', this.addAboutUsForm.get('footer_content').value);
  // formData.append('user_id', this._userId);
  this.apiService.addprivacypolicy(reqparams).subscribe( (result:any) => {
    if(result.success){
        this.toastr.success(result.message);
        //this.router.navigate['/privacy-policy-list']
        this.router.navigateByUrl('/privacy-policy-list');
      }
      console.log(result)
    });
  }
  else{

    let footer_id = this.getData._id
    let reqpara ={
      privacy :this.addPrivacyPolicyForm.get('privacy').value
    }
      //formData.append('privacy',this.addPrivacyPolicyForm.get('privacy').value)
   console.log('formData');
    this.apiService.editprivacy(footer_id , reqpara).subscribe((result :any ) =>{
        if(result.success){
          console.log('Hello');
          
            console.log(result.message)
            this.toastr.success(result.message);
            //this.router.navigate['/privacy-policy-list']
            this.router.navigateByUrl('/privacy-policy-list');
          }
        })
        
      }
    }
getContent(){
  let data ={
    id:this.content_id
  }
  this.apiService.getPrivacyPolicyById(data).subscribe((res :any)=>{
    console.log(res)
    if(res.success){
      this.getData = res.getData
      console.log(this.getData)
        this.addPrivacyPolicyForm.patchValue({
          privacy : this.getData.privacy
        })
        console.log('hi' + this.getData.privacy)
    }
  })
}
}
