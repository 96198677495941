import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ApiService } from 'src/app/utils/services/api.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-about-us-add',
  templateUrl: './about-us-add.component.html',
  styleUrls: ['./about-us-add.component.scss'],
})
export class AboutUsAddComponent implements OnInit {
  addAboutUsForm: FormGroup; // FormGroup instance for the form
  isSubmitting = false; // Flag to handle submission state

  constructor(
    private fb: FormBuilder, // FormBuilder for reactive forms
    private apiService: ApiService, // ApiService to make API calls
    private router: Router, // Router for navigation
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    // Initialize the form group with controls and validators
    this.addAboutUsForm = this.fb.group({
      // user_id: ['', Validators.required],
      name: ['', Validators.required],
      about_us: ['', Validators.required],
      // content: ['', Validators.required],
      about_us_image : [null, Validators.required],
    });
  }

  /**
   * Handles the file input change event.
   * Updates the 'img' form control with the selected file.
   */
  onFileChange(event: any): void {
    const files = event.target.files;
    if (files && files.length > 0) {
      const file = files[0];
      
      if (!file.type.startsWith('image/')) {
        console.error('Selected file is not an image.');
        this.addAboutUsForm.patchValue({ about_us_image: null });
        this.addAboutUsForm.get('about_us_image')?.updateValueAndValidity();
        return;
      }
      console.log(file, 'Selected file');
      const imageFormData = new FormData();
      imageFormData.append('uploadFile', file);
  
      this.apiService.uploadFile(imageFormData).subscribe({
        next: (uploadResponse: any) => {
          console.log('Image Upload Response:', uploadResponse);
  
          const fileData = uploadResponse.file_data[0];
          if (fileData) {
            console.log('Uploaded File Data:', fileData);
  
            this.addAboutUsForm.patchValue({ about_us_image: fileData });
            this.addAboutUsForm.get('about_us_image')?.updateValueAndValidity();
          } else {
            console.warn('Invalid upload response: No file data found');
          }
        },
        error: (uploadError) => {
          console.error('Image Upload Error:', uploadError);
        },
      });
    } else {
      console.warn('No file selected.');
    }
  }
  
  /**
   * Handles form submission to add About Us content.
   * Validates the form, uploads the image, and submits the form data.
   */
  addAboutUs(): void {
    if (this.addAboutUsForm.valid) {
      console.log('Form is valid:', this.addAboutUsForm.value);
      this.isSubmitting = true;
  
      // Prepare form data for submission
      const formData = new FormData();
      formData.append('name', this.addAboutUsForm.get('name')?.value); // Append name
      formData.append('about_us', this.addAboutUsForm.get('about_us')?.value); // Append about_us
  
      // Append the full image object to the form data
      const aboutUsImage = this.addAboutUsForm.get('about_us_image')?.value;
      if (aboutUsImage) {
        formData.append('about_us_image', JSON.stringify(aboutUsImage)); // Convert object to string
      } else {
        console.warn('No image data available.');
      }
  
      // Call the About Us API
      this.apiService.aboutUs(formData).subscribe({
        next: (response) => {
          console.log('About Us API Response:', response);
          this.isSubmitting = false;
          this.toastr.success(response['message']);
          this.router.navigate(['/about-us-list']); // Navigate to the list page
        },
        error: (aboutUsError) => {
          console.error('About Us API Error:', aboutUsError);
          this.toastr.error(aboutUsError.error['message']);
          this.isSubmitting = false;
        },
      });
    } else {
      console.error('Form is invalid:', this.addAboutUsForm.errors);
    }
  }
  
}
