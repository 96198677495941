
<!-- Edit About Us Form Start -->
<div class="content">
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12">
        <div class="card card-primary">
          <form [formGroup]="editAboutUsForm">
            <div class="card-body">
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="name">Title<span class="required"></span></label>
                    <input type="text" id="name" formControlName="name" class="form-control" placeholder="Enter Title" />
                    <div *ngIf="editAboutUsForm.get('name')?.invalid && editAboutUsForm.get('name')?.touched">
                      <small class="text-danger">Title is required.</small>
                    </div>
                  </div>
                </div>

                <div class="col-md-6">
                  <div class="form-group">
                    <label for="about_us_image">Image<span class="required"></span></label>
                    <input type="file" id="about_us_image" formControlName="about_us_image" (change)="onFileChange($event)" accept="image/*" class="form-control" />
                    <span>{{selectedFileName}}</span>
                    <div *ngIf="editAboutUsForm.get('about_us_image') && editAboutUsForm.get('about_us_image')?.touched">
                      <small class="text-danger">Image is required.</small>
                    </div>
                  </div>
                </div>

                <div class="col-md-12">
                  <div class="form-group">
                    <label for="about_us">Description<span class="required"></span></label>
                    <textarea id="about_us" formControlName="about_us" class="form-control"
                      placeholder="Enter Description"></textarea>
                    <div *ngIf="editAboutUsForm.get('about_us')?.invalid && editAboutUsForm.get('about_us')?.touched">
                      <small class="text-danger">Description is required.</small>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div class="card-footer text-right">
              <button type="button" class="btn btn-info" [disabled]="editAboutUsForm.invalid"
                (click)="editAboutUs()">Submit</button>
              <button type="button" class="btn btn-secondary ml-2" [routerLink]="['/about-us-list']">Cancel</button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Edit About Us Form End -->