<div class="content">
   <div class="container-fluid">
     <div class="row">
       <div class="col-md-12">
         <div class="card card-primary">
           <!-- form start -->
           <form [formGroup]="addVideoForm" (ngSubmit)="addVideo()">
            <div class="card-header pb-3">
              <div class="row">
                <div class="col-md-3" *ngIf="video_type === null"></div>
                <div class="col-md-3" *ngIf="video_type === null || video_type.split(' ')[0] === 'Internal'">
                  <div 
                    class="custom-control custom-radio"
                    style="text-align: center"
                  >
                    <input
                      class="custom-control-input"
                      type="radio"
                      id="internal_video"
                      formControlName="video_type"
                      value="internal_video"
                      (change)="handleChange($event)"
                    />
                    <label for="internal_video" class="custom-control-label"
                      >Internal</label
                    >
                  </div>
                </div>
                <div class="col-md-3" *ngIf="video_type === null || video_type.split(' ')[0] === 'External'">
                  <div
                    class="custom-control custom-radio"
                    style="text-align: center"
                  >
                    <input
                      class="custom-control-input"
                      type="radio"
                      id="external_video"
                      formControlName="video_type"
                      value="external_video"
                      (change)="handleChange($event)"
                    />
                    <label for="external_video" class="custom-control-label"
                      >External</label
                    >
                  </div>
                </div>
                <div class="col-md-3"></div>
              </div>
            </div>
 
             <div class="card-body">
               <div class="row">
                 <div class="col-md-4">
                   <div class="form-group">
                     <label for="title"
                       >Title<span class="required"></span></label>
                     <input
                       type="text"
                       class="form-control"
                       formControlName="title"
                       placeholder="Enter Title"
                     />
                     <div *ngIf="addVideoForm.get('title')?.invalid && addVideoForm.get('title')?.touched">
                      <small class="text-danger">Title is required.</small>
                    </div>
                   </div>
                 </div>
 
                 <div class="col-md-4" *ngIf="addVideoForm.value.video_type == 'internal_video'">
                   <div class="form-group">
                     <label>Video<span class="required"></span></label>
                     <div class="input-group">
                       <div class="custom-file">
                         <input
                           type="file"
                           accept="video/*"
                           (change)="onFileChange($event, 'video')"
                           class="custom-file-input"
                           name="video"
                           id="video"
                         />
                         <label class="custom-file-label" #importVdo for="video">
                           <span>Upload Video</span>
                         </label>
                       </div>
                     </div>
                   </div>
                 </div>

                 <div class="col-md-4" *ngIf="addVideoForm.value.video_type == 'external_video'">
                  <div class="form-group">
                    <label>Image<span class="required"></span></label>
                    <div class="input-group">
                      <div class="custom-file">
                        <input
                          type="file"
                          accept="image/*"
                          (change)="onFileChange($event, 'img')"
                          class="custom-file-input"
                          name="img"
                        />
                        <label class="custom-file-label" #importImg for="image">
                          <span>Upload Image</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                 <!-- <div class="col-md-4">
                  <div class="form-group">
                     <label for="description"
                     >Description<span class="required"></span></label>
                     <textarea
                     type="text"
                     class="form-control"
                     formControlName="description"
                     placeholder="Description"
                     ></textarea>
                  </div>
                 </div> -->
                 <div class="col-md-4" *ngIf="addVideoForm.value.video_type == 'external_video'">
                  <div class="form-group">
                    <label>Link<span class="required"></span></label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          ><i class="fas fa-link"></i
                        ></span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="link"
                        placeholder="Enter Link"
                      />
                    </div>
                    <div *ngIf="addVideoForm.get('link').invalid && addVideoForm.get('link').touched" class="text-danger">
                      <small *ngIf="addVideoForm.get('link').hasError('required')">
                        Link is required.
                      </small>
                      <small *ngIf="addVideoForm.get('link').hasError('pattern')">
                        Please enter a valid URL starting with http:// or https://.
                      </small>
                    </div>
                  </div>
                </div>
               </div>

               <div class="row" *ngIf="addVideoForm.value.video_type == 'external_video'">
                <div class="col-md-4">
                  <div class="form-group">
                    <label for="logo_title">Logo Title</label>
                    <input
                      type="text"
                      class="form-control"
                      formControlName="logo_title"
                      placeholder="Logo Title"
                    />
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label>Logo Image</label>
                    <div class="input-group">
                      <div class="custom-file">
                        <input
                          type="file"
                          accept="image/*"
                          (change)="onFileChange($event, 'logo_img')"
                          class="custom-file-input"
                          aria-describedby="imageFile"
                          name="logo_img"
                        />
                        <label class="custom-file-label" #importLogoImg for="logo_img">
                          <span>Upload Logo Image</span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-md-4">
                  <div class="form-group">
                    <label>Logo Link</label>
                    <div class="input-group mb-3">
                      <div class="input-group-prepend">
                        <span class="input-group-text"
                          ><i class="fas fa-link"></i
                        ></span>
                      </div>
                      <input
                        type="text"
                        class="form-control"
                        formControlName="logo_link"
                        placeholder="Logo Link"
                      />
                    </div>
                  </div>
                </div>
              </div>
 
               <div class="row">
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Select Sub-category<span class="required"></span
                      ></label>
                    <ng-select placeholder="Select Sub-category" [multiple]="true" formControlName="subCatName">
                      <ng-option *ngFor="let subCat of SubCats" [value]="subCat">{{ subCat }}</ng-option>
                    </ng-select>
                  </div>
                </div>
                <div class="col-md-4">
                  <div class="form-group">
                    <label>Active Till<span class="required"></span></label>
                    <input type="date" class="form-control" ngbDatepicker (click)="datePicker.toggle()" #datePicker="ngbDatepicker" formControlName="activeTillDate" [minDate]="minDate" placeholder="yyyy-mm-dd">
                  </div>
                </div>
               </div>
               <div class="row">
                <div class="col-md-4 ml-3 pt-4">
                  <div class="custom-control custom-checkbox">
                    <input
                     class="form-check-input"
                     type="checkbox"
                     id="status"
                     formControlName="status"
                     (change)="addVideoForm.get('status').setValue($event.target.checked ? 'active' : 'inactive')"
                     [checked]="addVideoForm.value.status == 'active'"
                    />
                    <label for="status" class="form-check-label">Active</label>
                  </div>
                </div>
               </div>
             </div>
             <!-- /.card-body -->
             <div class="card-footer text-right">
               <!-- <button type="submit" class="btn btn-info mr-2">Submit</button> -->
               <app-button
                [type]="'submit'"
                [block]="false"
                [text]="'Submit'"
                [loading]="isAuthLoading"
                [color]="'info'"
                [disabled]="isAuthDisabled"
              ></app-button>
              <button type="button" [disabled]="isAuthDisabled" class="btn btn-secondary ml-2" [routerLink]="['/videos-list']">Cancel</button>
             </div>
           </form>
         </div>
       </div>
     </div>
   </div>
 </div>
 
 <!-- ================================================================================================ -->
 