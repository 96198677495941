import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { NgbDatepickerConfig } from '@ng-bootstrap/ng-bootstrap';
import { DataTableDirective } from 'angular-datatables';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Subject } from 'rxjs';

@Component({
  selector: 'app-disclaimer',
  templateUrl: './disclaimer.component.html',
  styleUrls: ['./disclaimer.component.scss']
})
export class DisclaimerComponent implements OnInit {
  getData : any 
  addDisclaimerForm: FormGroup
  public isAuthLoading = false;
  public isAuthDisabled = false;
  public cancelCatRoute: string;
  config_ck = {
    uiColor: '#ffffff',
    toolbarGroups: [
    // { name: 'clipboard', groups: ['clipboard', 'undo'] },
    // { name: 'editing', groups: ['find', 'selection', 'spellchecker'] },
    // { name: 'links' }, { name: 'insert' },
    // { name: 'document', groups: ['mode', 'document', 'doctools'] },
    { name: 'basicstyles', groups: ['basicstyles', 'cleanup'] },
    { name: 'paragraph', groups: ['list', 'indent', 'blocks', 'align'] },
    // { name: 'styles' },
    { name: 'colors' }],
    resize_enabled: false,
    removePlugins: 'elementspath,save,magicline',
    extraPlugins: 'justify',
    colorButton_foreStyle: {
       element: 'font',
       attributes: { 'color': '#(color)' }
    },
    height: 188,
    removeDialogTabs: 'image:advanced;link:advanced',
    removeButtons: 'Subscript,Superscript,Anchor,Source,Table',
    format_tags: 'p;h1;h2;h3;pre;div'
 }
 private _userId: string = localStorage.getItem("id");
 @ViewChild(DataTableDirective) datatableElement: DataTableDirective
 category: string;
 footer_content: any;
  content_id: string;

 constructor(
   private formBuilder: FormBuilder,
   private config: NgbDatepickerConfig,
   private toastr: ToastrService,
   private route: ActivatedRoute,
   private router: Router,
   private apiService: ApiService
 ) { }
 // @ViewChild('importVdo', { static: false }) importVdo: ElementRef;
 // @ViewChild('importImg', { static: false }) importImg: ElementRef;
 // @ViewChild('importLogoImg', { static: false }) importLogoImg: ElementRef;

 ngOnInit(): void {
   this.category = this.router.url.split('/')[1];
   this.content_id = this.router.url.split('/')[2]
 

 this.addDisclaimerForm = new FormGroup({
   category_name : new FormControl(),
   disclaimer: new FormControl(),   
   status: new FormControl(),
 });
 this.addDisclaimerForm = this.formBuilder.group({
  
   // category_name: ['', [Validators.required]],    
   disclaimer: [[], [Validators.required]],   
   status: ['', []],
 });
 this.getContent()
}
AddAboutUsContent(){
  if(this.content_id == undefined){
 if (
   this.addDisclaimerForm.value.disclaimer.trim() == undefined ||
   this.addDisclaimerForm.value.disclaimer.trim() == ''
 ) {
   this.toastr.error('Please Enter Content');
   return false;
 }


 let reqparams = {
   "user_id" : this._userId,
   "status" : this.addDisclaimerForm.get('status').value,
   "disclaimer" : this.addDisclaimerForm.get('disclaimer').value,
   "category_name" : "Disclaimer"
 };

 // formData.append('status', this.addAboutUsForm.get('status').value);
 // formData.append('category_name', this.addAboutUsForm.get('category_name').setValue('About Us'));
 // formData.append('footer_content', this.addAboutUsForm.get('footer_content').value);
 // formData.append('user_id', this._userId);
 this.apiService.AddFooterContent(reqparams).subscribe((result:any) => {
   if(result.success){
     this.toastr.success(result.message);
     this.router.navigate['/disclaimer-list']
   }
   console.log(result)
 });

}
else{

  let footer_id = this.getData._id
  let reqpara ={
    "disclaimer" :this.addDisclaimerForm.get('disclaimer').value
    
  }
  this.apiService.editdiscl(footer_id , reqpara).subscribe((result :any ) =>{
      if(result.success){
          console.log(result.message)
          this.toastr.success(result.message);
          //this.router.navigate['/disclaimer-list']
          this.router.navigateByUrl('/disclaimer-list');
        }
      })
      
    }
  }
getContent(){
  let data ={
    id:this.content_id
  }
  this.apiService.getDisclaimerById(data).subscribe((res :any)=>{
    console.log(res)
    if(res.success){
      this.getData = res.getData
      console.log(this.getData)
        this.addDisclaimerForm.patchValue({
          disclaimer : this.getData.disclaimer
        })
        console.log('hi' + this.getData.disclaimer)
    }
  })
}

}
