import { Component, OnInit, ElementRef, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup, Validators, FormControl } from '@angular/forms';
import { ApiService } from '../../utils/services/api.service';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-dashboard-video',
  templateUrl: './dashboard-video.component.html',
  styleUrls: ['./dashboard-video.component.scss']
})
export class DashboardVideoComponent implements OnInit {
  addVideoForm: FormGroup;
  private video_id: any;
  public video_type: any;
  public SubCats: any;
  public minDate = undefined;
  private _userId: string = localStorage.getItem("id");
  public isAuthLoading = false;
  public isAuthDisabled = false;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private route: ActivatedRoute,
    private apiService: ApiService,
    private toastr: ToastrService
  ) {
    const current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
  }
  @ViewChild('importVdo', { static: false }) importVdo: ElementRef;
  @ViewChild('importImg', { static: false }) importImg: ElementRef;
  @ViewChild('importLogoImg', { static: false }) importLogoImg: ElementRef;
  ngOnInit(): void {
    this.addVideoForm = new FormGroup({
      video_type: new FormControl(),
      title: new FormControl(),
      logo_title: new FormControl(),
      link: new FormControl(),
      logo_link: new FormControl(),
      video: new FormControl(),
      img: new FormControl(),
      logo_img: new FormControl(),
      // description: new FormControl(),
      subCatName: new FormControl(),
      activeTillDate: new FormControl(),
      status: new FormControl(),
    });
    this.addVideoForm = this.formBuilder.group({
      video_type: ['', []],
      title: ['', [Validators.required]],
      logo_title: ['', [Validators.required]],
      link: ['', [Validators.required, Validators.pattern('https?://.+')]],
      logo_link: ['', [Validators.required]],
      logo_img: ['', [Validators.required]],
      // description: ['', [Validators.required]],
      video: ['', []],
      img: ['', [Validators.required]],
      subCatName: [[], [Validators.required]],
      activeTillDate: [null, Validators.required],
      status: ['', []],
    });

    this.getAllSubCat();

    this.video_id = this.route.snapshot.paramMap.get('videos_id');
    this.video_type = this.route.snapshot.paramMap.get('video_type');
    // console.log(this.news_id, this.news_type);
    if (this.video_id != undefined && this.video_id != '' && this.video_type != undefined && this.video_type != '') {
      this.editVideo(this.video_id, this.video_type.split(" ")[0]);
    } else {
      this.addVideoForm.get('video_type').setValue('internal_video');
      this.addVideoForm.get('status').setValue('active');
    }
  }

  getAllSubCat() {
    this.apiService.getAllsubcatVideo().subscribe((resp: any) => {
      this.SubCats = [];
      for (let i = 0; i < resp.data.length; i++) {
        if (resp.data[i].status === "active") {
          this.SubCats.push(resp.data[i].sub_category_name);
        }
      }
    });
  }

  onFileChange(event, set) {
    if (event.target.files.length > 0) {
      const file = event.target.files[0];
  
      // Check if the file type is valid for video
      if (set === 'video' && file.type.startsWith('video/')) {
        this.addVideoForm.get(set).setValue(file);  // Set the video file in the form control
        this.importVdo.nativeElement.innerText = file.name;  // Update the UI to show the file name
      } 
      // Check if the file type is valid for image (for img)
      else if (set === 'img' && file.type.startsWith('image/')) {
        this.addVideoForm.get(set).setValue(file);  // Set the image file in the form control
        this.importImg.nativeElement.innerText = file.name;  // Update the UI to show the file name
      } 
      // Check if the file type is valid for logo image (for logo_img)
      else if (set === 'logo_img' && file.type.startsWith('image/')) {
        this.addVideoForm.get(set).setValue(file);  // Set the logo image in the form control
        this.importLogoImg.nativeElement.innerText = file.name;  // Update the UI to show the file name
      } 
      // If the file is not of a valid type, show an error
      else {
        this.toastr.error('Invalid file type. Please upload the correct file.');
      }
    }
  }
  


  async addVideo() {
    if (
      this.addVideoForm.value.title.trim() == undefined ||
      this.addVideoForm.value.title.trim() == ''
    ) {
      this.toastr.error('Please Enter Title', '', {
        timeOut: 1000,  // Time in milliseconds (1000ms = 1 second)
        progressBar: true,  // Optional: Show progress bar
        closeButton: true   // Optional: Show close button
      });
          return false;
    }
    if (this.addVideoForm.value.video_type == 'external_video') {
      if (
        this.addVideoForm.value.link.trim() == undefined ||
        this.addVideoForm.value.link.trim() == ''
      ) {
        this.toastr.error('Please Enter Link');
        return false;
      }
    }
    // if (
    //   this.addVideoForm.value.description.trim() == undefined ||
    //   this.addVideoForm.value.description.trim() == ''
    // ) {
    //   this.toastr.error('Please Enter Video Description');
    //   return false;
    // }
    if (this.addVideoForm.value.subCatName.length === 0) {
      this.toastr.error('Please Select Sub-category');
      return false;
    }
    if (
      this.addVideoForm.value.activeTillDate == undefined ||
      this.addVideoForm.value.activeTillDate == '' ||
      this.addVideoForm.value.activeTillDate == null
    ) {
      this.toastr.error('Please Enter Active Till Date');
      return false;
    }

    if (this.video_id != undefined && this.video_id != '' && this.video_type != undefined && this.video_type != '') {
      this.updateVideo(this.video_id, this.video_type.split(" ")[0]);
    } else {
      if (this.addVideoForm.value.video_type == 'internal_video') {
        if (
          this.addVideoForm.value.video.name == undefined ||
          this.addVideoForm.value.video.name == ''
        ) {
          this.toastr.error('Please Select Video');
          return false;
        }
      }
      if (this.addVideoForm.value.video_type == 'external_video') {
        if (
          this.addVideoForm.value.img.name == undefined ||
          this.addVideoForm.value.img.name == ''
        ) {
          this.toastr.error('Please Select Image');
          return false
        }
      }
      this.isAuthLoading = true;
      this.isAuthDisabled = true;
      let date = `${this.addVideoForm.value.activeTillDate.month}/${this.addVideoForm.value.activeTillDate.day}/${this.addVideoForm.value.activeTillDate.year}`
      let formData = new FormData();
      if (this.addVideoForm.value.video_type == 'internal_video') {
        formData.append('user_id', this._userId);
        formData.append('status', this.addVideoForm.get('status').value);
        formData.append('all_video', this.addVideoForm.get('video').value);
        formData.append('title', this.addVideoForm.get('title').value);
        formData.append('active_till', date);
        // formData.append('description', this.addVideoForm.get('description').value);
        for (let i = 0; i < this.addVideoForm.value.subCatName.length; i++) {
          formData.append('sub[]', this.addVideoForm.get('subCatName').value[i]);
        }
        await this.apiService.addNewVideo(formData).subscribe((result: any) => {
          if (result.success) {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            this.toastr.success(result.message);
            this.router.navigateByUrl('/videos-list');
          } else {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            this.toastr.error(result.message);
          }
        });
      } else if (this.addVideoForm.value.video_type == 'external_video') {
        formData.append('user_id', this._userId);
        formData.append('status', this.addVideoForm.get('status').value);
        formData.append('all_images', this.addVideoForm.get('img').value);
        formData.append('all_images2', this.addVideoForm.get('logo_img').value);
        formData.append('title', this.addVideoForm.get('title').value);
        formData.append('logo_title', this.addVideoForm.get('logo_title').value);
        formData.append('link', this.addVideoForm.get('link').value);
        formData.append('logo_link', this.addVideoForm.get('logo_link').value);
        formData.append('active_till', date);
        // formData.append('description', this.addVideoForm.get('description').value);
        for (let i = 0; i < this.addVideoForm.value.subCatName.length; i++) {
          formData.append('sub[]', this.addVideoForm.get('subCatName').value[i]);
        }
        await this.apiService.addNewExternalVideo(formData).subscribe((result: any) => {
          if (result.success) {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            this.toastr.success(result.message);
            this.router.navigateByUrl('/videos-list');
          } else {
            this.isAuthLoading = false;
            this.isAuthDisabled = false;
            this.toastr.error(result.message);
          }
        });
      }
    }

  }

  async editVideo(id, type) {
    let dataForForm = {
      id: id,
    };
    if (type === 'Internal') {
      await this.apiService.getVideoById(dataForForm).subscribe((result: any) => {
        if (result.success) {
          console.log(result.getData);
          let videoData: any = result.getData;
          this.addVideoForm.get('video_type').setValue('internal_video');
          this.addVideoForm.get('subCatName').setValue(videoData.sub_category_name);
          let date = new Date(videoData.active_till);
          this.addVideoForm.patchValue({
            title: videoData.title,
            video: videoData.video_file[0]?.src,
            // description: videoData.description,
            activeTillDate: {
              day: date.getUTCDate() + 1,
              month: date.getUTCMonth() + 1,
              year: date.getUTCFullYear()
            },
          });
          if (videoData.status == "active") {
            this.addVideoForm.get('status').setValue('active');
          }
          setTimeout(() => {
            this.importVdo.nativeElement.innerText = videoData.video_file[0]?.orgName;
          }, 1000);
        } else {
          this.toastr.error(result.message);
        }
      });
    } else if (type === 'External') {
      await this.apiService.getExternalVideoById(dataForForm).subscribe((result: any) => {
        if (result.success) {
          console.log(result.getData);
          let videoData: any = result.getData;
          this.addVideoForm.get('video_type').setValue('external_video');
          this.addVideoForm.get('subCatName').setValue(videoData.sub_category_name);
          let date = new Date(videoData.active_till);
          this.addVideoForm.patchValue({
            title: videoData.title,
            img: videoData.news_image[0]?.src,
            // video: videoData.video_file[0]?.src,
            // description: videoData.description,
            link: videoData.link,
            logo_title: videoData.logo_title,
            logo_img: videoData.logo_image[0]?.src,
            logo_link: videoData.logo_link,
            activeTillDate: {
              day: date.getUTCDate() + 1,
              month: date.getUTCMonth() + 1,
              year: date.getUTCFullYear()
            },
          });
          if (videoData.status == "active") {
            this.addVideoForm.get('status').setValue('active');
          }
          setTimeout(() => {
            this.importImg.nativeElement.innerText = videoData.news_image[0]?.orgName;
            if (
              videoData.logo_image[0]?.orgName === undefined ||
              videoData.logo_image[0]?.orgName === '' ||
              videoData.logo_image[0]?.orgName === null
            ) {
              this.importLogoImg.nativeElement.innerText = 'Upload Logo Image';
            } else {
              this.importLogoImg.nativeElement.innerText = videoData.logo_image[0]?.orgName;
            }
          }, 1000);
        } else {
          this.toastr.error(result.message);
        }
      });
    }
  }

  async updateVideo(id, type) {
    let formData = new FormData();
    if (type == 'Internal') {
      if (
        this.addVideoForm.value.video?.name != undefined ||
        this.addVideoForm.value.video?.name != ''
      ) {
        formData.append('all_video', this.addVideoForm.get('video').value);
      }
    }
    if (type == 'External') {
      if (
        this.addVideoForm.value.img?.name != undefined ||
        this.addVideoForm.value.img?.name != ''
      ) {
        formData.append('all_images', this.addVideoForm.get('img').value);
      }
      if (
        this.addVideoForm.value.logo_img?.name != undefined ||
        this.addVideoForm.value.logo_img?.name != ''
      ) {
        formData.append('all_images2', this.addVideoForm.get('logo_img').value);
      }
    }
    this.isAuthLoading = true;
    this.isAuthDisabled = true;
    let date = `${this.addVideoForm.value.activeTillDate.month}/${this.addVideoForm.value.activeTillDate.day}/${this.addVideoForm.value.activeTillDate.year}`

    if (type == 'Internal') {
      formData.append('status', this.addVideoForm.get('status').value == "" || this.addVideoForm.get('status').value == "inactive" ? "inactive" : "active");
      formData.append('user_id', this._userId);
      formData.append('title', this.addVideoForm.get('title').value);
      formData.append('active_till', date);
      // formData.append('all_video', this.addVideoForm.get('video').value);
      // formData.append('description', this.addVideoForm.get('description').value);
      for (let i = 0; i < this.addVideoForm.value.subCatName.length; i++) {
        formData.append('sub[]', this.addVideoForm.get('subCatName').value[i]);
      }

      await this.apiService.updateVideo(formData, id).subscribe((result: any) => {
        if (result.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          this.toastr.success(result.message);
          this.router.navigateByUrl('/videos-list');
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          this.toastr.error(result.message);
        }
      });
    } else if (type == 'External') {
      formData.append('status', this.addVideoForm.get('status').value == "" || this.addVideoForm.get('status').value == "inactive" ? "inactive" : "active");
      formData.append('user_id', this._userId);
      formData.append('title', this.addVideoForm.get('title').value);
      formData.append('logo_title', this.addVideoForm.get('logo_title').value);
      formData.append('link', this.addVideoForm.get('link').value);
      formData.append('logo_link', this.addVideoForm.get('logo_link').value);
      formData.append('active_till', date);
      // formData.append('all_video', this.addVideoForm.get('video').value);
      // formData.append('description', this.addVideoForm.get('description').value);
      for (let i = 0; i < this.addVideoForm.value.subCatName.length; i++) {
        formData.append('sub[]', this.addVideoForm.get('subCatName').value[i]);
      }

      await this.apiService.updateExternalVideo(formData, id).subscribe((result: any) => {
        if (result.success) {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          this.toastr.success(result.message);
          this.router.navigateByUrl('/videos-list');
        } else {
          this.isAuthLoading = false;
          this.isAuthDisabled = false;
          this.toastr.error(result.message);
        }
      });
    }

  }
  handleChange(eve) {
    var target = eve.target;
    if (target.checked) {
      // document.getElementById('').setValue('')
      this.addVideoForm.get('title').setValue('')
      this.addVideoForm.get('subCatName').setValue('')
      this.addVideoForm.value.active_till == ''
      console.log('checked')
    }
  }

}
