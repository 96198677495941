<aside #mainSidebar class="main-sidebar elevation-4 sidebar-dark-info">
  <!-- Brand Logo -->
  <a [routerLink]="['/']" class="brand-link navbar-info" id="scroll">
    <img
      src="assets/img/Favi.png"
      alt="AdminLTE Logo"
      class="brand-image img-circle elevation-3"
      style="opacity: 0.8"
    />
    <span class="brand-text font-weight-bolder">&nbsp;Pharma Zeal</span>
  </a>
  <!-- Sidebar -->
  <div class="sidebar">
    <!-- Sidebar user (optional) -->
    <div class="user-panel mt-3 pb-3 mb-3 d-flex">
      <div class="image">
        <img
          src="assets/img/user2-160x160.jpg"
          class="img-circle elevation-2"
          alt="User Image"
        />
      </div>
      <div class="info d-block" style="color:#c2c7d0;">
        {{userData?.firstname + " " + userData?.lastname}}
        <!-- <a [routerLink]="['/profile']" class="d-block">{{userData?.firstname + " " + userData?.lastname}}</a> -->
      </div>
    </div>
    <!-- Sidebar Menu -->
    <nav class="mt-2">
      <ul
        class="nav nav-pills nav-sidebar flex-column"
        data-widget="treeview"
        role="menu"
      >
        <!-- Add icons to the links using the .nav-icon class with font-awesome or any other icon font library -->
        <li class="nav-item" (click)="scroll()">
          <a [routerLink]="['/']" class="nav-link">
            <i class="nav-icon fas fa-tachometer-alt"></i>
            <p>Dashboard</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="+userData?.role === 0" (click)="scroll()">
          <a [routerLink]="['/admin-list']" class="nav-link">
            <i class="nav-icon fas fa-user-tie"></i>
            <p>Admin Panel</p>
          </a>
        </li>
        <li class="nav-item" (click)="scroll()">
          <a [routerLink]="['/user-list']" class="nav-link">
            <i class="nav-icon fas fa-users"></i>
            <p>User Panel</p>
          </a>
        </li>
        <li class="nav-item" (click)="scroll()">
          <a [routerLink]="['/user-details']" class="nav-link">
            <i class="nav-icon fas fa-user"></i>
            <p>User Login Details</p>
          </a>
        </li>
        <li class="nav-item" *ngIf="+userData?.role === 0" (click)="scroll()">
          <a [routerLink]="['/change-category-name']" class="nav-link">
            <i class="nav-icon fas fa-edit"></i>
            <p>Change Category Name</p>
          </a>
        </li>
        <li class="nav-item has-treeview" *ngIf="CategoryName" >
          <a href="#" class="nav-link">
            <i class="nav-icon fas fa-newspaper"></i>
            <p><i class="fas fa-angle-right right"></i>{{ CategoryName.Category_A }}</p>
          </a>
          <ul class="nav nav-treeview" (click)="scroll()">
            <li class="nav-item">
              <a [routerLink]="['/news/create-news']" class="nav-link">
                <p class="submenu">Add </p>
              </a>
            </li>
            <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/news-list']" class="nav-link">
                <p class="submenu"> List</p>
              </a>
            </li>
            <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/news-subcategory-list']" class="nav-link">
                <p class="submenu">Sub-Category</p>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item has-treeview" *ngIf="CategoryName">
          <a href="#" class="nav-link">
            <i class="nav-icon fas fa-gavel"></i>
            <p><i class="fas fa-angle-right right"></i>{{ CategoryName.Category_B }}</p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/regulatory-guidelines/create-regulatory-guidelines']" class="nav-link">
                <p class="submenu">Add </p>
              </a>
            </li>
            <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/regulatory-guidelines-list']" class="nav-link">
                <p class="submenu">List</p>
              </a>
            </li>
            <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/regulatory-guidelines-subcategory-list']" class="nav-link">
                <p class="submenu">Sub-Category</p>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item has-treeview" *ngIf="CategoryName">
          <a href="#" class="nav-link">
            <i class="nav-icon fas fa-industry"></i>
            <p><i class="fas fa-angle-right right"></i>{{ CategoryName.Category_C }}</p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/gmp/create-gmp']" class="nav-link">
                <p class="submenu">Add </p>
              </a>
            </li>
            <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/gmp-list']" class="nav-link">
                <p class="submenu"> List</p>
              </a>
            </li>
            <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/gmp-subcategory-list']" class="nav-link">
                <p class="submenu">Sub-Category</p>
              </a>
            </li>
            <!-- <li class="nav-item" *ngIf="+userData?.role === 0">
              <a [routerLink]="['/gmp/gmp-membership-days']" class="nav-link">
                <p class="submenu">Membership Days</p>
              </a>
            </li> -->
          </ul>
        </li>
        <li class="nav-item has-treeview" *ngIf="CategoryName">
          <a href="#" class="nav-link">
            <i class="nav-icon fas fa-briefcase"></i>
            <p>{{ CategoryName.Category_D }}<i class="fas fa-angle-right right"></i></p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/business/create-business']" class="nav-link">
                <p class="submenu">Add </p>
              </a>
            </li>
            <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/business-list']" class="nav-link">
                <p class="submenu"> List</p>
              </a>
            </li>
            <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/business-subcategory-list']" class="nav-link">
                <p class="submenu">Sub-Category</p>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item has-treeview" *ngIf="CategoryName">
          <a href="#" class="nav-link">
            <i class="nav-icon fas fa-boxes"></i>
            <p>{{ CategoryName.Category_E }}<i class="fas fa-angle-right right"></i></p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/products-services/create-products-services']" class="nav-link">
                <p class="submenu">Add </p>
              </a>
            </li>
            <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/products-services-list']" class="nav-link">
                <p class="submenu"> List</p>
              </a>
            </li>
            <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/products-services-subcategory-list']" class="nav-link">
                <p class="submenu">Sub-Category</p>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item has-treeview" *ngIf="CategoryName">
          <a href="#" class="nav-link">
            <i class="nav-icon fas fa-video"></i>
            <p><i class="fas fa-angle-right right"></i>{{ CategoryName.Category_F }}</p>
          </a>
          <ul class="nav nav-treeview">
            <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/videos/add-videos']" class="nav-link">
                <p class="submenu">Add </p>
              </a>
            </li>
            <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/videos-list']" class="nav-link">
                <p class="submenu"> List</p>
              </a>
            </li>
            <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/video-subcategory-list']" class="nav-link">
                <p class="submenu">Sub-Category</p>
              </a>
            </li>
          </ul>
        </li>
        <li class="nav-item" >
          <a  class="nav-link">
            <!-- <i class="nav-icon fas fa-about"></i> -->
            <!-- <i class="nav-icon fas fa-info-square"></i> -->
            <i class="nav-icon fas fa-info-circle"></i>
            <p><i class="fas fa-angle-right right"></i>About Us</p>
          </a>
          <ul class="nav nav-treeview">
            <!-- <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/about-us']" class="nav-link">
                <p class="submenu">Add </p>
              </a>
            </li> -->
           <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/about-us-list']" class="nav-link">
                <p class="submenu"> List</p>
              </a>
            </li>
            <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/about-us-add']" class="nav-link">
                <p class="submenu"> Add</p>
              </a>
            </li>
           </ul>
        </li>
        <li class="nav-item"  >
          <a  class="nav-link">
            <i class="nav-icon fas fa-user-shield"></i>
            <p><i class="fas fa-angle-right right"></i>Privacy Policy</p>
          </a>
          <ul class="nav nav-treeview">
            <!-- <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/privacy-policy']" class="nav-link">
                <p class="submenu">Add </p>
              </a>
            </li> -->
            <li class="nav-item" (click)="scroll()">
               <a [routerLink]="['/privacy-policy-list']" class="nav-link">
                 <p class="submenu"> List</p>
               </a>
             </li>
            </ul>
        </li>
        <li class="nav-item"  >
          <a  class="nav-link">
            <i class="nav-icon fas fa-clipboard-list"></i>
            <p><i class="fas fa-angle-right right"></i>Terms & Conditions</p>
          </a>
          <ul class="nav nav-treeview">
            <!-- <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/terms-and-condition']" class="nav-link">
                <p class="submenu">Add </p>
              </a>
            </li> -->
            <li class="nav-item" (click)="scroll()">
               <a [routerLink]="['/terms-and-conditions-list']" class="nav-link">
                 <p class="submenu"> List</p>
               </a>
             </li>
            </ul>
        </li>
        <li class="nav-item" >
          <a  class="nav-link">
            <i class="nav-icon fas fa-exclamation-triangle"></i>
            <p><i class="fas fa-angle-right right"></i>Disclaimer</p>
          </a>
          <ul class="nav nav-treeview">
            <!-- <li class="nav-item" (click)="scroll()">
              <a [routerLink]="['/disclaimer']" class="nav-link">
                <p class="submenu">Add </p>
              </a>
            </li> -->
            <li class="nav-item" (click)="scroll()">
               <a [routerLink]="['/disclaimer-list']" class="nav-link">
                 <p class="submenu"> List</p>
               </a>
             </li>
            </ul>
        </li>
        
      </ul>
    </nav>
    <!-- /.sidebar-menu -->
  </div>
  <!-- /.sidebar -->
</aside>